<template>
  <div class="sidebar">
    <div class="friendlink-right">
      <div class="friendlink-wrapper">
        <div class="friendlink-menu-button" :class="sideObj.open ? 'open' : 'close'" @click="sideObj.open = !sideObj.open">
          <i class="fa" :class="sideObj.open ? 'fa-chevron-up' : 'fa-ellipsis-v'"></i>
        </div>
        <div class="friendlink-menu-item" :class="sideObj.open ? 'fade-show' : 'fade-hide'" :style="{ visibility: sideObj.open ? 'visible' : 'hidden' }">
          <ul v-for="(item, index) in    sideObj.list   ">
            <li :class="item.qrcode ? 'downloadLink' : ''" @click="itemClick(item, index)">
              <a class="friendlink-menu-nav" :class="!item.flx ? 'not-flx' : ''">
                <img :src="item.flx ? item.img : comApi.getNormalImg(item.imagePc)" alt="">
                <span></span>
                <div v-if="item.flx && item.hot && mailInfo.mailUnreadNumAll > 0" class="news-icon">
                  <i class="news-dot"></i>
                </div>
              </a>
              <div v-if="item.flx && item.qrcode && Object.keys(lobbyInfo.VersionGet).length > 0" class="qrcode">
                <div class="title">{{t('APP Download')}}</div>
                <vue-qr :logo-src="comApi.getLogoImg()" class="qr-img" :text="lobbyInfo.VersionGet.android.UrlPath" :size="100" :margin='10'>
                </vue-qr>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo, mailInfo } = useGetters("user", ["userInfo", "mailInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

//侧边栏
let sideObj = reactive({
  open: false,
  list: [
    {
      img: require('@/assets/pc/common/icon_notice.png'),
      flx: true,
      flag: 'notice',
      hot: true,
    },
    // {
    //     img: require('@/assets/pc/common/icon_app.png'),
    //     flx: true,
    //     flag: 'qrcode',
    //     qrcode: true,
    // },
  ]
})

addSideItemInList();

function addSideItemInList() {
  let arr = [];
  lobbyInfo.value.MainCustomContent.forEach(info => {
    if (info.type == "service" && info.status == true) {
      arr.push(info);
    }
  });
  arr.sort((a, b) => {
    return a.sort - b.sort
  })

  sideObj.list.splice(2, 0, ...arr);
}

function itemClick(item, index) {
  if (item.flx) {
    if (item.flag == 'notice') {
      if (!isLogged.value) {
        comApi.toLoginOrRegister(0);
        return;
      }
      router.push(
        {
          path: '/member/mailBox/mailBoxNew'
        }
      )
    } else if (item.flag == 'qrcode') {
      router.push('/download');
    }
  } else {
    if (item.keywords_content == "customerLiveChat") {
      $act.openBrowser(item.content);
    } else {
      // $act.openBrowser(item.content)
      // window.open(item.content)
      bus.emit(bus.event.systemInfoPop, { bool: true, data: item });
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  right: 0;
  top: 20%;
  z-index: 10;

  .friendlink-right {
    margin-top: 10px;
    *zoom: 1;
    top: 140px;
    right: 12px;
    width: auto;
    position: absolute;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;

    .friendlink-wrapper {
      .friendlink-menu-button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #0071e3;
        cursor: pointer;
        color: #fff;
        display: block;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
      }

      .friendlink-menu-button:hover,
      .open {
        background: #ffbf00;
        color: #ffffff;
      }

      .friendlink-menu-item {
        -webkit-transition: all 0.9s ease;
        transition: all 0.9s ease;
        opacity: 1;
        height: auto;
        position: absolute;
        z-index: 1;
        height: auto;
        opacity: 1;
        *zoom: 1;

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          *zoom: 1;
          float: left;
          -webkit-transition: all 0.5s ease;
          transition: all 0.5s ease;

          li {
            position: relative;
            display: inline-block;
            text-align: center;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
            margin: 5px 0 0;

            a {
              color: #fff;
              text-decoration: none;
              cursor: pointer;
            }

            .friendlink-menu-nav {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: #ffffff;
              cursor: pointer;
              display: block;

              img {
                width: 100%;
              }

              .news-icon {
                position: relative;

                .news-dot {
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  background: #dd4d4f;
                  right: 9px;
                  top: -35px;
                  position: absolute;
                  z-index: 101;
                }
              }
            }

            .not-flx {
              img {
                width: 100%;
              }
            }
          }

          li:hover {
            transform: translateY(5px);

            .friendlink-menu-nav {
              background: #b1faff;
            }
          }

          .downloadLink {
          }

          .downloadLink:hover .qrcode {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .fade-show {
        transition: all 0.9s ease;
      }

      .fade-hide {
        opacity: 0;
        display: none;
      }

      .qrcode {
        right: calc(100% + 30px);
        top: 50%;
        transform: translate(20px, -50%);
        z-index: 9999;
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-image: linear-gradient(
            -2deg,
            #efefff 0%,
            #f6f6ff 50%,
            #fcfcff 100%
          ),
          linear-gradient(#ffffff, #ffffff);
        padding: 8px;
        -webkit-box-shadow: 0 0 14px 0 #fff;
        box-shadow: 0 0 14px 0 #fff;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        .title {
          font-weight: 800;
          font-size: 12px;
          margin-bottom: 10px;
          color: #0f161f;
        }

        .qr-img {
          max-width: 100px;
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}
</style>