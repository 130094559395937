<template>
  <div class="systemInfoPop">
    <el-dialog v-model="show" :show-close="false" @close="close()">
      <div class="close-wrapper">
        <button type="button" class="dark-close" @click="close()"></button>
      </div>
      <div class="captcha-popup ">
        <div class="remindinfo-block">
          <div class="title-block">
            <div class="popup-icon"></div>
            <h2 class="remindinfo-title">{{ props.data.title }}</h2>
          </div>
          <div class="remindinfo-bg">
            <div v-if="props.data.keywords_content == 'customerTel'">
              <div class="tel">
                <span>{{ props.data.content }}</span>
                <i class="fa fa-copy" @click="copyUrl(props.data.content)"></i>
              </div>
            </div>
            <div v-else class="remindinfo-inner">
              <h4>{{t('Click or Scan QRcode')}}</h4>
              <div class="link">
                <vue-qr class="qr-img" :logo-src="comApi.getNormalImg(props.data.imagePc)" :text="props.data.content" :size="200" @click="clickQr()">
                </vue-qr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const show = ref(true);
const props = defineProps({
  data: {}
})

function clickQr() {
  $act.openBrowser(props.data.content)
}

function copyUrl(text) {
  tools.copyText(text).then(
    (succ) => {
      ElMessage.success(t("Copy successful"));
    },
    (err) => {
    }
  );
}

function close() {
  bus.emit(bus.event.systemInfoPop, { bool: false, data: {} });
}
</script>

<style lang="scss" scoped>
.systemInfoPop {
  position: relative;
  text-align: center;

  :deep(.el-dialog) {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 0px;
    border-radius: 2px;
    position: relative;
    margin: 0 auto 50px;
    width: 700px;
    max-width: 100%;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      color: #0f161f;
      font-size: 14px;
      overflow: visible;
      padding: 0;

      .close-wrapper {
        .dark-close {
          position: absolute;
          top: 80px;
          right: 40px;
          z-index: 10;
          padding: 0;
          border: 0;
          text-align: center;
          opacity: 0.5;
          cursor: pointer;
          width: 30px;
          height: 30px;
          -webkit-appearance: none;
          background: url("@/assets/pc/common/dark-close.png");
        }
      }

      .captcha-popup {
        padding-top: 45px;

        .remindinfo-block {
          border-radius: 12px;
          border: solid 2px #ff9384;
          position: relative;
          background-image: linear-gradient(-1deg, #fff4f4 21%, #ffffff 100%),
            linear-gradient(#ffffff, #ffffff);

          .title-block {
            padding: 20px;
            text-align: center;
            position: relative;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;

            .popup-icon {
              width: 90px;
              height: 90px;
              border-radius: 50%;
              position: absolute;
              margin: auto;
              left: 0;
              right: 0;
              top: -45px;
              background: #ffbf00;
            }

            .popup-icon:before {
              content: "";
              display: block;
              background-repeat: no-repeat;
              background-position: 50%;
              background-size: 50%;
              filter: grayscale(100%) brightness(250%);
              width: 100%;
              height: 100%;
              position: absolute;
              background-image: url("@/assets/pc/pop/systeminfo-icon.png");
            }

            .remindinfo-title {
              word-break: break-all;
              margin: 50px 0 0;
              word-wrap: break-word;
              font-size: 30px;
              font-weight: 500;
            }
          }

          .remindinfo-bg {
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            margin-top: -1px;
            padding-top: 1px;

            .remindinfo-inner {
              *zoom: 1;
              text-align: center;
              padding: 20px;
            }

            h4 {
              font-size: 18px;
              margin-top: 10px;
              margin-bottom: 10px;
              font-weight: 500;
            }

            .tel {
              display: flex;
              min-height: 100px;
              margin: auto;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              font-weight: 500;
              gap: 10px;

              .fa {
              }
            }

            .link {
              cursor: pointer;
              display: inline-block;

              img {
                width: 200px;
                height: 200px;
              }
            }
          }
        }
      }
    }
  }
}
</style>